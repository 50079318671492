/**
 * Autologin
 * This component allow to start the interface with params added in the URL
 * It's a handler to get the value of params in the URL
 *
 * Params
 * n    =   Name      => user name
 * id   =   Id        => Voxeet conference name
 * lng  =   Language  => language code
 * g    =   Gender    => user gender (tts voice)
 *
 * example:
 *
 * http://path_url?n=User_example&id=sadasdas&lng=en-US&g=male
 */

import React from 'react';
import { getValueFromQuery } from '../../utils/miscUtils';

const AutoLogin = props => {
    const params = window.location.search;
    let name = null;
    let id = null;
    let language = null;
    let gender = null;
    if (params !== '') {
        name = getValueFromQuery(params, 'n');
        id = getValueFromQuery(params, 'id');
        language = getValueFromQuery(params, 'lng');
        gender = getValueFromQuery(params, 'g');
        const childrenWithExtraProp = React.Children.map(props.children, child => {
            return React.cloneElement(child, {
                initName: name,
                conferenceName: id,
                initLanguage: language,
                initGender: gender,
            });
        });
        return childrenWithExtraProp;
    } else {
        return (
            <div style={{ width: '100vw', textAlign: 'center' }}>
                <h1>Must pass params to start</h1>
                <p>?n=[username]&id=[conferenceName]&lng=[language]&g=[gender]</p>
                <ul style={stylesAutologin.list}>
                    <li style={stylesAutologin.listItem}>
                        <strong>username:</strong> User name, e.g: John Doe
                    </li>
                    <li style={stylesAutologin.listItem}>
                        <strong>conferenceName:</strong> Name to connect voxeet conference
                    </li>
                    <li style={stylesAutologin.listItem}>
                        <strong>language:</strong> Language code, e.g: en-US, es-ES, fr-FR, etc.
                    </li>
                    <li style={stylesAutologin.listItem}>
                        <strong>gender:</strong> User gender. e.g: male | female.
                    </li>
                </ul>
            </div>
        );
    }
};

export default AutoLogin;

const stylesAutologin = {
    list: {
        listStyle: 'none',
        marginTop: '2em',
    },
    listItem: {
        margin: '1em 0',
    },
};
