import React from 'react';
import PropTypes from 'prop-types';
import { fetchSpeechToText } from '../request/speechtotext';
import Subtitles from '../subtitles';
import Chat from '../chat';
import speechcapture from './lib';
import SttUI from './component';
import { useTokenContext, useStateValue } from '../store';

let captureCfg = {};

function SpeechToTextWrapper(props) {
    const { endpoint, username } = props;
    const { token } = useTokenContext();
    const [{ stt }, dispatch] = useStateValue();

    /**
     * Called when speech has been captured
     * @param audioBuffer
     * @param type speechcapture.AUDIO_RESULT_TYPE
     */
    const onSpeechCaptured = (audioBuffer, type) => {
        handleAudioBuffer(audioBuffer, type);
    };

    /**
     * Called when a speechcapture error has occurred
     * @param error
     */
    const onSpeechError = error => {
        stopCapture();
    };

    /**
     * Called when the speechcapture status changes
     * @param code
     */
    const onSpeechStatus = code => {
        switch (code) {
            case speechcapture.STATUS.CAPTURE_STARTED:
                console.log('Capture Started!');
                break;
            case speechcapture.STATUS.CAPTURE_STOPPED:
                console.log('Capture Stopped!');

                break;
            case speechcapture.STATUS.SPEECH_STARTED:
                console.log('Speech Started!');
                break;
            case speechcapture.STATUS.ENCODING_ERROR:
                console.log('Encoding Error!');
                break;
            case speechcapture.STATUS.CAPTURE_ERROR:
                console.log('Capture Error!');
                break;
            case speechcapture.STATUS.SPEECH_ERROR:
                console.log('Speech Error!');
                break;
            case speechcapture.STATUS.SPEECH_MAX_LENGTH:
                console.log('Max Length Occurred!');
                break;
            case speechcapture.STATUS.SPEECH_MIN_LENGTH:
                console.log('Min Length Occurred!');
                break;
            default:
            case speechcapture.STATUS.SPEECH_STOPPED:
                console.log('Speech Stopped!');
                break;
        }
    };

    /**
     * Start speech capture from microphone
     */
    const startCapture = () => {
        try {
            if (!speechcapture.isCapturing()) {
                var audioSourceType = 0;
                var audioResultType = 1;
                //Speech Parameters
                var speechThreshold = 20;
                var speechMin = 10;
                var speechMax = 6000;
                var speechAllowedDelay = 200;
                var analysisChunkLength = 100;
                var sampleRate = 16000;
                var bufferSize = 16384;
                var compressPauses = false;
                var preferGUM = true;
                var detectOnly = false;

                captureCfg = {
                    audioSourceType: parseInt(audioSourceType),
                    audioResultType: audioResultType,
                    speechDetectionThreshold: speechThreshold,
                    speechDetectionMinimum: speechMin,
                    speechDetectionMaximum: speechMax,
                    speechDetectionAllowedDelay: speechAllowedDelay,
                    analysisChunkLength: analysisChunkLength,
                    sampleRate: sampleRate,
                    bufferSize: bufferSize,
                    compressPauses: compressPauses,
                    preferGUM: preferGUM,
                    detectOnly: detectOnly,
                };

                speechcapture.start(captureCfg, onSpeechCaptured, onSpeechError, onSpeechStatus);
            }
        } catch (e) {}
    };

    /**
     * Stop speech capture from microphone
     */
    const stopCapture = () => {
        try {
            if (speechcapture.isCapturing()) {
                speechcapture.stop();
            }
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Handle buffer audio
     * @param audioBuffer
     * @param type speechcapture.AUDIO_RESULT_TYPE
     */
    const handleAudioBuffer = (audioBuffer, type) => {
        try {
            switch (type) {
                case speechcapture.AUDIO_RESULT_TYPE.WAV_BLOB:
                    appendWAVAudioBuffer(audioBuffer);
                    break;
                default:
                    console.log(
                        'handleAudioBuffer - Unknown type of Audio result: ' +
                            captureCfg.audioSourceType,
                    );
                    break;
            }
        } catch (e) {
            console.log('handleAudioBuffer ex: ' + e);
        }
    };

    /**
     * Convert audiobuffer into wav file
     * @param {Object} audioBuffer
     * @return {void}
     */
    const appendWAVAudioBuffer = audioBuffer => {
        const { language } = stt;
        try {
            var reader = new FileReader();
            //var self = this;
            reader.onload = async function(evt) {
                var audio = document.createElement('audio');
                audio.controls = true;
                audio.src = evt.target.result;
                audio.type = 'audio/wav';

                // Request API text recognition
                const speechDetected = await fetchSpeechToText(
                    endpoint,
                    audioBuffer,
                    language.value,
                    undefined, // headers
                );
                if (
                    speechDetected[endpoint.valueResponse] &&
                    speechDetected[endpoint.valueResponse].trim() !== ''
                ) {
                    handleTextRecognized(speechDetected, language.value);
                }
            };
            reader.readAsDataURL(audioBuffer);
        } catch (e) {
            console.log('appendWAVAudioBuffer ex: ' + e);
        }
    };

    const handleTextRecognized = (data, language) => {
        const text = data[endpoint.valueResponse];
        let msg = null;

        if (stt.target === 'subtitles') {
            msg = Subtitles.buildMessageSubtitle(username, language, text);
            dispatch({
                type: 'SUBTITLES_msgFromComponent',
                value: msg,
            });
        } else if (stt.target === 'chat') {
            msg = Chat.buildMessageChat(username, language, text, 'own');
            dispatch({
                type: 'CHAT_msgFromComponent',
                value: msg,
            });
        }
    };

    return <SttUI {...props} startCapture={startCapture} stopCapture={stopCapture} />;
}

SpeechToTextWrapper.defaultProps = {
    initialVisible: true,
    initialActive: false,
    initialTarget: 'subtitles',
    withUI: true,
};

SpeechToTextWrapper.propTypes = {
    username: PropTypes.string.isRequired,
    initialLanguage: PropTypes.string.isRequired,
    initialTarget: PropTypes.string.isRequired,
    endpoint: PropTypes.object.isRequired,
};

const SpeechToText = {
    SpeechToTextUI: SpeechToTextWrapper,
};

export default SpeechToText;
