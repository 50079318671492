import React, { useState, useEffect } from 'react';
import { fetchTranslate } from '../request/translate';
import { useStateValue, useTokenContext } from '../store';
import Select from '../components/select';
import parserHyperlink from '../utils/parserHyperlink';

let firstPlayAudio = false;
import {
    Cog,
    TrashAlt,
    Times,
    ArrowRight,
    ArrowLeft,
    MicrophoneSlash,
    Microphone,
    VolumeMute,
    VolumeDown,
    Exchange,
    ForwardIcon,
} from '../assets/svg/icons';

function SubtitlesUI(props) {
    const {
        buildMessageSubtitle,
        channelIdPubnub,
        endpoint,
        defaultBilingual,
        initialSourceLanguage,
        initialTargetLanguage,
        initialVisible,
        languagesPreTranslate,
        newSubtitle,
        postRequestSubtitles,
        preRequestSubtitles,
        provider,
        pubnub,
        render,
        renderSubtitle,
        fontSizeSubtitles,
        showInput,
        showButtonCloseComponent,
        showSourceLanguageSelect,
        showTargetLanguageSelect,
        showFlipButton,
        showSpeedOption,
        speechRecognition,
        style,
        subtitles,
        ttsOption,
        username,
    } = props;

    const [
        {
            subtitles: { targetLanguage, sourceLanguage, visible, messageFromOtherComponent },
            tts: {
                active: activeTTS,
                gender: genderTTS,
                voice: voiceTTS,
                playbackRate: playbackRateTTS,
            },
            stt,
        },
        dispatch,
    ] = useStateValue();
    const { token, audioTTS, languages } = useTokenContext();
    const [inputSubtitles, setInputSubtitles] = useState(null);
    const [fontSize, setFontSize] = useState(fontSizeSubtitles);
    const [bilingual, setBilingual] = useState(false);
    const [visibleOptions, setVisibleOptions] = useState(false);
    const [speedOptionMenu, setSpeedOptionMenu] = useState(false);

    // When component is mounted, set default values to context
    useEffect(() => {
        const defaultSourceLanguage = languages.find(lng => lng.value === initialSourceLanguage);
        const defaultTargetLanguage = languages.find(lng => lng.value === initialTargetLanguage);
        dispatch({
            type: 'SUBTITLES_setup',
            value: {
                sourceLanguage: defaultSourceLanguage,
                targetLanguage: defaultTargetLanguage,
                visible: initialVisible,
            },
        });
        if (defaultBilingual) {
            setBilingual(true);
        }
    }, []);

    // incoming message from third party communication dependency
    // e.g. pubnub
    useEffect(() => {
        if (newSubtitle) {
            const { name, speakerLanguage, text, multiple, translations } = newSubtitle;
            if (text && text.trim() !== '') {
                let findTranslation = null;
                if (multiple) {
                    findTranslation = translations.find(
                        translation =>
                            translation.targetLanguage.split('-')[0] ===
                            targetLanguage.value.split('-')[0],
                    );
                }
                if (multiple && findTranslation) {
                    handleIncomingMessage(
                        name,
                        targetLanguage.value,
                        findTranslation[endpoint.valueResponse],
                    );
                } else {
                    handleIncomingMessage(name, speakerLanguage, text);
                }
            }
        }
    }, [newSubtitle]);

    // incoming message from third party Component
    // e.g. STT, Popular phrases, etc.
    useEffect(() => {
        if (messageFromOtherComponent) {
            const { name, speakerLanguage, text } = messageFromOtherComponent;

            displaySubtitle(speakerLanguage, targetLanguage.value, text, name);

            handleMessageFromOtherComponent(messageFromOtherComponent);
        }
    }, [messageFromOtherComponent]);

    // Update scroll on Subtitles list when there's a new text
    useEffect(() => {
        handleScroll();
    }, [subtitles]);

    // font size of subtitles content
    useEffect(() => {
        if (fontSizeSubtitles && fontSizeSubtitles > 0 && fontSizeSubtitles < 6) {
            setFontSize(fontSizeSubtitles);
        }
    }, [fontSizeSubtitles]);

    /**
     * Handle incoming message from third party components
     * @param {String} name User name who wrote the message
     * @param {String} incomeLanguage Code language of text
     * @param {String} textIncome Text wrote
     */
    const handleIncomingMessage = async (name, incomingLanguage, textIncome) => {
        let text = textIncome;

        // bilingual validation
        // if incomingLanguage (messageFromOtherComponent) is different of sourceLanguage (Subtitle component)
        // we have to translate that text to render like bilingual text (first line) with sourceLanguage idiom

        // const auth = token.ttt.find(elem => elem.vendor === sourceLanguage.vendor);
        if (bilingual && incomingLanguage !== sourceLanguage.value) {
            const responseRequest = await fetchTranslate(
                endpoint,
                text,
                incomingLanguage,
                sourceLanguage.value,
                undefined,
            );
            text = responseRequest[endpoint.valueResponse];
            displaySubtitle(sourceLanguage.value, targetLanguage.value, text, name);
        } else {
            displaySubtitle(incomingLanguage, targetLanguage.value, text, name);
        }
    };

    const handleMessageFromOtherComponent = async msg => {
        const { name, speakerLanguage, text } = msg;

        let translations = null;
        if (languagesPreTranslate) {
            translations = await fetchMultipleTranslations(
                text,
                speakerLanguage,
                languagesPreTranslate,
            );
        }

        // get info from messageFromOtherComponent to make new object of message
        // with or without translations
        const newMessage = buildMessageSubtitle(name, speakerLanguage, text, translations);

        if (provider === 'pubnub' && pubnub) {
            pubnub.publish({
                channel: channelIdPubnub,
                message: JSON.stringify(newMessage),
            });
        }
    };

    /**
     * Show the text in the body of component like text list
     * @param {string} sourceLanguage   Source language "en"
     * @param {string} targetLanguage   Target language "es"
     * @param {string} text             Subtitle text
     * @param {string} name             User name
     */
    const displaySubtitle = async (sourceLanguage, targetLanguage, text, name) => {
        const msg = buildMessageSubtitle(name, sourceLanguage, text);

        if (text && text.trim() !== '') {
            if (sourceLanguage !== targetLanguage) {
                const subtitlesTranslated = await handleRequestSubtitle(
                    text,
                    sourceLanguage,
                    targetLanguage,
                );
                if (subtitlesTranslated) {
                    const msgTranslated = buildMessageSubtitle(
                        name,
                        targetLanguage,
                        subtitlesTranslated,
                    );

                    // bilingual validation
                    // if sourceLanguage is differente of targetLanguage and bilingual is enabled
                    // we have to show two lines per subtitles text
                    // first line: text with sourceLanguage idiom
                    // second line: text with targetLanguage idiom
                    if (bilingual) {
                        msgTranslated.bilingualText = text;
                    }
                    renderSubtitle(msgTranslated);

                    if (activeTTS && voiceTTS) {
                        dispatch({
                            type: 'TTS_setNewTTS',
                            value: {
                                text: subtitlesTranslated,
                                language: targetLanguage,
                            },
                        });
                    }
                }
            } else {
                if (window.dictionaries) {
                    const responsePreRequest = await preRequestSubtitles(
                        text,
                        sourceLanguage,
                        targetLanguage,
                    );
                    msg.text = responsePreRequest.text;
                    msg.speakerLanguage = responsePreRequest.targetLanguage;
                }

                renderSubtitle(msg);

                if (activeTTS && voiceTTS) {
                    dispatch({
                        type: 'TTS_setNewTTS',
                        value: {
                            text,
                            language: targetLanguage,
                        },
                    });
                }
            }
        }
    };

    // Handle text to make Request to API REST
    // You may handle data before and after make request with custom functions passed by props
    // util to delete/add words, change languages, etc.
    const handleRequestSubtitle = async (text, sourceLanguage, targetLanguage) => {
        let textPre = null;
        let sourceLanguagePre = null;
        let targetLanguagePre = null;

        // handle data before API request
        if (window.dictionaries) {
            const responsePreRequest = await preRequestSubtitles(
                text,
                sourceLanguage,
                targetLanguage,
            );
            textPre = responsePreRequest.text;
            sourceLanguagePre = responsePreRequest.sourceLanguage;
            targetLanguagePre = responsePreRequest.targetLanguage;
        }

        const responseRequest = await fetchTranslate(
            endpoint,
            textPre || text,
            sourceLanguagePre || sourceLanguage,
            targetLanguagePre || targetLanguage,
            undefined,
        );

        // handle data after API response
        if (postRequestSubtitles) {
            const responsePostRequest = await postRequestSubtitles(
                text,
                sourceLanguage,
                targetLanguage,
            );
            return responsePostRequest.text;
        }

        return responseRequest[endpoint.valueResponse];
    };

    /**
     * Handle input subtitles
     * @param {Object} e Class Event of component
     * @return {void}
     */
    const handleInputSubtitles = async e => {
        if (e.key === 'Enter' && !e.shiftKey && inputSubtitles && inputSubtitles.trim() !== '') {
            e.persist();
            e.preventDefault();

            displaySubtitle(sourceLanguage.value, targetLanguage.value, inputSubtitles, username);
            setInputSubtitles(null);

            let textToPubnub = inputSubtitles;
            if (window.dictionaries) {
                const responsePreRequest = await preRequestSubtitles(
                    inputSubtitles,
                    sourceLanguage.value,
                    targetLanguage.value,
                );
                textToPubnub = responsePreRequest.text;
            }

            let translations = null;
            if (languagesPreTranslate) {
                translations = await fetchMultipleTranslations(
                    textToPubnub,
                    sourceLanguage.value,
                    languagesPreTranslate,
                );
            }

            const msg = buildMessageSubtitle(
                username,
                sourceLanguage.value,
                textToPubnub,
                translations,
            );

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
        }
        if (e.key === 'Enter' && !e.shiftKey && (!inputSubtitles || inputSubtitles.trim() == '')) {
            e.preventDefault();
            return false;
        }
    };

    /**
     * Handle multiple translations before send message to pubnub channel
     * @param {String} inputSubtitles Value of input subtitles
     * @param {String} sourceLanguage language of source user
     * @param {Array}  languagesToTranslate Array of languages to make translations
     * @return {Array} Translations result
     */
    const fetchMultipleTranslations = (inputSubtitles, sourceLanguage, languagesToTranslate) => {
        // fetch api request of each language pre translate
        const arrayOfPromises = languagesToTranslate.map(async targetLanguage => {
            const responseRequest = await fetchTranslate(
                endpoint,
                inputSubtitles,
                sourceLanguage,
                targetLanguage,
                undefined,
            );

            return responseRequest;
        });

        // solve each pending promise
        // https://dev.to/jamesliudotcc/how-to-use-async-await-with-map-and-promise-all-1gb5
        return Promise.all(arrayOfPromises).then(values => {
            return values.map((value, index) => ({
                sourceLanguage: sourceLanguage,
                targetLanguage: languagesToTranslate[index],
                ...value,
            }));
        });
    };

    /**
     * Update class of each <p> element to change font size
     * @param {Integer} size Value to set new font size
     * @return {void}
     */
    const handleFontSize = size => {
        setFontSize(size);
    };
    ts;
    /**
     * Set scroll to the bottom when new text is rendered
     */
    const handleScroll = () => {
        var container = document.getElementById('no_text_verification');
        container.scrollTop = container.scrollHeight;
    };

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Delete subtitles list, set array by default []
     */
    const deleteContent = () => {
        renderSubtitle(null);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'SUBTITLES_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleSourceLanguage = language => {
        dispatch({
            type: 'SUBTITLES_changeSourceLanguage',
            value: language,
        });

        // Update value of STT language
        dispatch({
            type: 'STT_changeLanguage',
            value: language,
        });
    };

    /**
     * Handle target language
     * @param {String} language en | es | fr
     */
    const handleTargetLanguage = language => {
        dispatch({
            type: 'SUBTITLES_changeTargetLanguage',
            value: language,
        });

        // Update value of TTS language to request with the right sourceLanguage param (/TextToSpeech endpoint)
        dispatch({
            type: 'TTS_changeLanguage',
            value: language,
        });

        // Update value of TTS voice to request with the right voice param (/TextToSpeech endpoint)
        // there are languages that doesn't have voices, must set up voice = null
        const newLanguage = languages.find(lng => lng.value === language.value);
        const supportTTS = newLanguage.services.indexOf('tts') > -1;
        dispatch({
            type: 'TTS_changeVoice',
            value: supportTTS || null,
        });
    };

    const handleSTT = () => {
        dispatch({
            type: 'STT_changeTarget',
            value: 'subtitles',
        });
        dispatch({
            type: 'STT_changeActive',
            value: !stt.active,
        });
    };

    const handleTTS = () => {
        dispatch({
            type: 'TTS_changeActive',
            value: !activeTTS,
        });
        handleAutoPlay();
    };

    const handleAutoPlay = () => {
        if (!firstPlayAudio) {
            const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isiOS || isSafari) {
                const promise = audioTTS.play();
                if (promise !== undefined) {
                    promise
                        .catch(error => {
                            // Auto-play was prevented
                            console.log(error);
                        })
                        .then(() => {
                            // Auto-play started
                            audioTTS.pause();
                        });
                }
            }
            firstPlayAudio = true;
        }
    };

    const handleFlipButton = () => {
        if (sourceLanguage.value !== targetLanguage.value) {
            handleSourceLanguage(targetLanguage);
            handleTargetLanguage(sourceLanguage);
        }

        if (stt.active) {
            dispatch({
                type: 'STT_changeActive',
                value: false,
            });
            setTimeout(() => {
                dispatch({
                    type: 'STT_changeActive',
                    value: true,
                });
            }, 100);
        }
    };

    const handleSpeedOptionMenu = () => {
        setSpeedOptionMenu(!speedOptionMenu);
    };

    const handlePlayBackRateTTS = value => {
        dispatch({
            type: 'TTS_changePlayBackRate',
            value,
        });
        setSpeedOptionMenu(false);
    };

    const printName = (sub, index) => {
        const isArabic = sub.speakerLanguage.split('-')[0] === 'arabic';
        if (subtitles[index - 1] === undefined)
            return (
                <p className={`subtitles-name font-size-${fontSize}`} dir={isArabic ? 'rtl' : ''}>
                    {sub.name}
                </p>
            );
        else if (subtitles[index - 1].name !== sub.name)
            return (
                <p className={`subtitles-name font-size-${fontSize}`} dir={isArabic ? 'rtl' : ''}>
                    {sub.name}
                </p>
            );
        else if (subtitles[index - 1].name === sub.name) return null;
    };

    const renderSubtitles = () => {
        const leftToRightText = (sub, index) => {
            const textToRender = parserHyperlink(sub.text);
            return (
                <div key={`sub-${index}`} className="ltr-text">
                    {printName(sub, index)}
                    {sub.bilingualText ? (
                        <p className={`font-size-${fontSize} bilingual-text`}>
                            <ArrowRight />
                            &nbsp;
                            {sub.bilingualText}
                        </p>
                    ) : null}
                    <p className={`font-size-${fontSize}`}>
                        <ArrowRight />
                        &nbsp;
                        {textToRender}
                    </p>
                </div>
            );
        };

        const rightToLeftText = (sub, index) => {
            const textToRender = parserHyperlink(sub.text);
            return (
                <div key={`sub-${index}`} className="rtl-text">
                    {printName(sub, index)}
                    {sub.bilingualText ? (
                        <p className={`font-size-${fontSize} bilingual-text`}>
                            <ArrowRight />
                            &nbsp;
                            {sub.bilingualText}
                        </p>
                    ) : null}
                    <p className={`font-size-${fontSize}`} dir="rtl">
                        <ArrowLeft />
                        &nbsp;
                        {textToRender}
                    </p>
                </div>
            );
        };
        return subtitles.map((sub, index) => {
            if (sub.speakerLanguage.split('-')[0] === 'arabic') {
                return rightToLeftText(sub, index);
            } else {
                return leftToRightText(sub, index);
            }
        });
    };

    const subtitlesRendered = renderSubtitles();

    return (
        <div className={`wrapper-container ${!visible ? 'container-disabled' : ''}`} style={style}>
            <div id="subtitles" className={`container-options`}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Subtitles</span>
                    </div>
                    <div className="header-options">
                        {/* render props */}
                        {render && render(subtitles)}
                        {/* render props */}

                        {showSpeedOption && (
                            <div style={{ display: 'flex' }}>
                                <button className="a-element" onClick={handleSpeedOptionMenu}>
                                    <ForwardIcon />
                                </button>
                                {speedOptionMenu && (
                                    <div className="wrapper-playback-rate">
                                        <div className="list-playback-rate">
                                            <ul>
                                                <li
                                                    className={
                                                        playbackRateTTS === 0.5 ? 'active' : ''
                                                    }
                                                    onClick={() => handlePlayBackRateTTS(0.5)}
                                                >
                                                    0.5x
                                                </li>
                                                <li
                                                    className={
                                                        playbackRateTTS === 0.75 ? 'active' : ''
                                                    }
                                                    onClick={() => handlePlayBackRateTTS(0.75)}
                                                >
                                                    0.75x
                                                </li>
                                                <li
                                                    className={
                                                        playbackRateTTS === 1 ? 'active' : ''
                                                    }
                                                    onClick={() => handlePlayBackRateTTS(1)}
                                                >
                                                    1x
                                                </li>
                                                <li
                                                    className={
                                                        playbackRateTTS === 1.25 ? 'active' : ''
                                                    }
                                                    onClick={() => handlePlayBackRateTTS(1.25)}
                                                >
                                                    1.25x
                                                </li>
                                                <li
                                                    className={
                                                        playbackRateTTS === 1.5 ? 'active' : ''
                                                    }
                                                    onClick={() => handlePlayBackRateTTS(1.5)}
                                                >
                                                    1.5x
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {ttsOption && voiceTTS && (
                            <button
                                className={`a-element ${activeTTS ? 'color-green' : ''}`}
                                onClick={handleTTS}
                            >
                                {activeTTS ? <VolumeDown /> : <VolumeMute />}
                            </button>
                        )}
                        <button className="a-element" onClick={deleteContent}>
                            <TrashAlt />
                        </button>
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>
                        {showButtonCloseComponent ? (
                            <button className="a-element" onClick={closeComponent}>
                                <Times />
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="box-subtitle">
                    <div
                        id="no_text_verification"
                        className={`body-container ${!visibleOptions ? 'options-disabled' : ''} ${
                            !showInput ? 'no-input' : ''
                        }`}
                    >
                        {subtitlesRendered}
                    </div>
                    {showInput ? (
                        <div
                            className={`wrapper-input-subtitles ${
                                speechRecognition ? 'wrapper-input-subtitles-group' : ''
                            }`}
                        >
                            {speechRecognition ? (
                                <div className="wrapper-button-speech-subtitles">
                                    <button
                                        type="button"
                                        className={`btn ${
                                            stt.active && stt.target === 'subtitles'
                                                ? 'btn-green'
                                                : ''
                                        }`}
                                        disabled={stt.active && stt.target !== 'subtitles'}
                                        onClick={handleSTT}
                                    >
                                        {stt.active && stt.target === 'subtitles' ? (
                                            <Microphone />
                                        ) : (
                                            <MicrophoneSlash />
                                        )}
                                    </button>
                                </div>
                            ) : null}
                            <div className="wrapper-input-subtitles">
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Type text here"
                                    rows="4"
                                    value={inputSubtitles || ''}
                                    onKeyPress={handleInputSubtitles}
                                    onClick={handleAutoPlay}
                                    onChange={e => setInputSubtitles(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''} ${
                            !showInput ? 'no-input' : ''
                        }`}
                    >
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper">
                                    <span className="settings-container-label">
                                        Subtitles font size
                                    </span>
                                    <div className="size-subtitles">
                                        <label style={{ fontSize: 10 }}>A</label>
                                        <input
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="1"
                                            value={fontSize}
                                            onChange={e => handleFontSize(e.target.value)}
                                        ></input>
                                        <label style={{ fontSize: 14 }}>A</label>
                                    </div>
                                </div>
                                {showInput &&
                                (showTargetLanguageSelect || showSourceLanguageSelect) ? (
                                    <div className="settings-container-wrapper d-flex justify-content-between">
                                        <div style={{ justifyContent: 'center' }}>
                                            <span className="settings-container-label">
                                                Bilingual
                                            </span>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={bilingual}
                                                    onChange={e => setBilingual(e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                        {showFlipButton && (
                                            <div className="d-flex align-items-end flipbutton">
                                                <div className="flipbutton-wrapper">
                                                    <button
                                                        type="button"
                                                        aria-label="Flip languages"
                                                        onClick={handleFlipButton}
                                                    >
                                                        <Exchange />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            <div className="settings-container">
                                {showTargetLanguageSelect ? (
                                    <div className="settings-container-wrapper target-language">
                                        <span className="settings-container-label">
                                            Target Language
                                        </span>
                                        <div>
                                            <Select
                                                handleChange={handleTargetLanguage}
                                                data={languages}
                                                defaultValue={languages.find(
                                                    item => item.value === initialTargetLanguage,
                                                )}
                                                value={targetLanguage}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {showInput && showSourceLanguageSelect ? (
                                    <div className="settings-container-wrapper source-language">
                                        <span className="settings-container-label">
                                            Source Language
                                        </span>
                                        <div>
                                            <Select
                                                handleChange={handleSourceLanguage}
                                                data={languages}
                                                defaultValue={languages.find(
                                                    item => item.value === initialTargetLanguage,
                                                )}
                                                value={sourceLanguage}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubtitlesUI;
