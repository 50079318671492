import React, { createContext, useContext, useReducer } from 'react';
import { subtitlesReducer, initialStateSubtitles } from '../subtitles/reducer';
import { chatReducer, initialStateChat } from '../chat/reducer';
import { textToSpeechReducer, initialStateTextToSpeech } from '../tts/reducer';
import { speechToTextReducer, initialStateSpeechToText } from '../stt/reducer';

// Main reducer
// Need to insert each component (Subtitles, Chat, TTS, STT)
// to handle global state
const mainReducer = ({ subtitles, chat, tts, stt }, action) => ({
    subtitles: subtitlesReducer(subtitles, action),
    chat: chatReducer(chat, action),
    tts: textToSpeechReducer(tts, action),
    stt: speechToTextReducer(stt, action),
});

// Global initial state with multiple state reducers
// This allow start the state at the App.js
const mainState = {
    subtitles: initialStateSubtitles,
    chat: initialStateChat,
    tts: initialStateTextToSpeech,
    stt: initialStateSpeechToText,
};

export const StateContext = createContext();
export const TokenContext = createContext();
export const StateProvider = ({ tokens, audioTTS, languages, children }) => (
    <StateContext.Provider value={useReducer(mainReducer, mainState)}>
        <TokenContext.Provider value={{ token: tokens, audioTTS, languages }}>
            {children}
        </TokenContext.Provider>
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
export const useTokenContext = () => useContext(TokenContext);
