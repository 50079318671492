import React, { useState, useEffect } from 'react';
import { useStateValue, useTokenContext } from '../store';
import { GroupedSelect } from '../components/select';
import { Cog, Times, Microphone, MicrophoneSlash } from '../assets/svg/icons';

function SttUI(props) {
    const {
        initialLanguage,
        initialTarget,
        initialVisible,
        initialActive,
        startCapture,
        stopCapture,
    } = props;

    const [
        {
            stt: { language, visible, active },
        },
        dispatch,
    ] = useStateValue();
    const { languages } = useTokenContext();
    const [visibleOptions, setVisibleOptions] = useState(false);

    // When component is mounted, set default values to context
    useEffect(() => {
        const defaultLanguage = languages.find(
            language => language.value.toLowerCase() === initialLanguage.toLowerCase(),
        );

        dispatch({
            type: 'STT_setup',
            value: {
                language: defaultLanguage,
                visible: initialVisible,
                active: initialActive,
                target: initialTarget,
            },
        });
    }, []);

    useEffect(() => {
        if (active) startCapture();
        else stopCapture();
    }, [active]);

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'STT_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleLanguage = language => {
        dispatch({
            type: 'STT_changeLanguage',
            value: language,
        });
    };

    /**
     * Handle active STT
     */
    const handleActive = () => {
        dispatch({
            type: 'STT_changeActive',
            value: !active,
        });
    };

    if (!props.withUI) return null;

    const defaultLanguage = languages.find(
        opt => opt.value.toLowerCase() === initialLanguage.toLowerCase(),
    );

    return (
        <div
            className={`wrapper-container ${!visible ? 'container-disabled' : ''}`}
            style={visible ? props.style : { margin: 0, overflow: 'hidden' }}
        >
            <div id="stt" className="container-options">
                <div className="header-container">
                    <div className="header-title">
                        <span>Speak</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>
                        <button className="a-element" onClick={closeComponent}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="box-stt">
                    <div className={`body-container ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div>
                            <div className="wrapper-btn-stt">
                                <button
                                    className={`btn-circle btn ${
                                        active ? 'btn-green' : 'btn-primary'
                                    }`}
                                    type="button"
                                    onClick={handleActive}
                                >
                                    {active ? <Microphone /> : <MicrophoneSlash />}
                                </button>
                                <label>&nbsp;{active ? 'Stop speech' : 'Start speech'}</label>
                            </div>
                            <div className="description-stt">
                                <p>Select microphone in your system sound settings.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper">
                                    <span className="settings-container-label">Language</span>
                                    <div>
                                        <GroupedSelect
                                            handleChange={handleLanguage}
                                            data={languages}
                                            defaultValue={defaultLanguage || null}
                                            value={language}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SttUI;
