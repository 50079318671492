const fetchLanguages = async () => {
    return await fetch('https://core.worldwidetechconnections.com/languages')
        .then(response => response.json())
        .then(languages => {
            const sortedLanguages = sortLanguages(languages);
            return sortedLanguages.map((item, index) => ({
                id: index,
                label: item.name,
                value: item.code,
                img: item.flag,
                services: item.services,
            }));
        });
};

const sortLanguages = data => {
    let aux = data.slice();
    aux.sort(function(a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

    // Find spanish internation and english to put at the top of the languages list
    const spanishInternational = aux.find(lng => lng.code === 'spanish-international');
    const englishUsa = aux.find(lng => lng.code === 'english-united-states');

    // Filter spanish and english idioms
    let spanishArray = aux.filter(lng => lng.code.split('-')[0] === 'spanish');
    let englishArray = aux.filter(lng => lng.code.split('-')[0] === 'english');

    spanishArray.unshift(spanishInternational);
    englishArray.unshift(englishUsa);

    const languagesArray = aux.filter(
        item => item.code.split('-')[0] !== 'spanish' && item.code.split !== 'english',
    );

    // Sort English, Spanish, rest of languages
    const arraySorted = [...englishArray, ...spanishArray, ...languagesArray];

    return arraySorted;
};

export default fetchLanguages;
