export const initialStateSubtitles = {
    sourceLanguage: null,
    targetLanguage: null,
    visible: true,
    messageFromOtherComponent: null, // could be SpeechToText, PopularPhrases or another component who wants to share text with Subtitles component
};

export function subtitlesReducer(state, action) {
    switch (action.type) {
        case 'SUBTITLES_setup':
            return {
                ...state,
                ...action.value,
            };

        case 'SUBTITLES_changeSourceLanguage':
            return {
                ...state,
                sourceLanguage: action.value,
            };

        case 'SUBTITLES_changeTargetLanguage':
            return {
                ...state,
                targetLanguage: action.value,
            };

        case 'SUBTITLES_changeVisible':
            return {
                ...state,
                visible: action.value,
            };

        case 'SUBTITLES_msgFromComponent':
            return {
                ...state,
                messageFromOtherComponent: action.value,
            };

        default:
            return state;
    }
}
