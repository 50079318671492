import axios from 'axios';

/**
 * Fetch token to make request to API /Translate /SpeechToText /TextToSpeech
 * @param {String} apiKey Vendor Api Key (MSFT, Google, Deepl, Yandex, etc)
 */
const authenticateVendor = async (url, method, apiKey, headers) => {
    const { data } = await axios({
        headers: {
            Authorization: apiKey,
            ...headers,
        },
        method,
        url,
    });
    return data;
};

/**
 * Update the token field of each vendor with response of API call
 * @param {Object} endpoint Endpoint configuration to make the request
 * @param {Object} item     Vendor information
 */
const setTokenVendor = async (endpoint, item) => {
    if (item.token === undefined) {
        const response = await authenticateVendor(
            endpoint.url,
            endpoint.method,
            item.apiKey,
            item.headers,
        );
        item = { ...item, ...response };
    }

    return item;
};

/**
 *
 * @param {Array} vendors List of credentials by vendor
 * @param {Object} endpoint Endpoint configuration to make the request
 */
const getTokens = async (vendors, endpoint) => {
    const promisesTranslate = vendors.ttt.map(async item => setTokenVendor(endpoint, item));
    const translateResolved = await Promise.all(promisesTranslate);

    const promisesSpeechToText = vendors.stt.map(async item => setTokenVendor(endpoint, item));
    const sttResolved = await Promise.all(promisesSpeechToText);

    const promisesTextToSpeech = vendors.tts.map(async item => setTokenVendor(endpoint, item));
    const ttsResolved = await Promise.all(promisesTextToSpeech);

    return {
        ttt: translateResolved,
        stt: sttResolved,
        tts: ttsResolved,
    };
};

export { authenticateVendor, getTokens };
