export const handlePreRequestSubtitles = async (text, sourceLanguage, targetLanguage) => {
    // https://stackoverflow.com/a/5069776
    // replace multiple strings by arrays given
    if (window.dictionaries !== undefined) {
        String.prototype.replaceArray = function(find, replace) {
            var replaceString = this;
            for (var i = 0; i < find.length; i++) {
                var search = new RegExp(find[i], 'i');
                replaceString = replaceString.replace(search, replace[i]);
            }
            return replaceString;
        };
        let find = null;
        let replace = null;
        let newText = null;

        const dictionary = window.dictionaries.find(
            dictionary =>
                dictionary.sourceLanguage === sourceLanguage.split('-')[0] &&
                dictionary.targetLanguage === targetLanguage.split('-')[0],
        );

        if (dictionary !== undefined) {
            find = dictionary.replacements.map(item => item.from);
            replace = dictionary.replacements.map(item => item.to);
            newText = text.replaceArray(find, replace);
        } else {
            newText = text;
        }

        return {
            text: newText,
            sourceLanguage,
            targetLanguage,
        };
    } else {
        return {
            text,
            sourceLanguage,
            targetLanguage,
        };
    }
};

export const getDictionaries = async () => {
    try {
        const response = await fetch(
            `${process.env.PUBLIC_URL}/dictionaries/dictionaries.json`,
        ).then(res => res.json());
        return response;
    } catch (e) {
        return null;
    }
};
