import React from 'react';
import PropTypes from 'prop-types';
import SubtitlesUI from './component';
import pubnub from '../pubnub';
import { handlePreRequestSubtitles } from '../utils/dictionaries';

const buildMessageSubtitle = (name, speakerLanguage, text, translations) => {
    return {
        module: 'subtitles',
        time: new Date().toTimeString().split(' ')[0],
        name,
        speakerLanguage,
        text,
        multiple: translations ? true : false,
        translations,
    };
};

class SubtitlesWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.renderSubtitle = this.renderSubtitle.bind(this);
        this.handleMessagePubnub = this.handleMessagePubnub.bind(this);
        this.state = {
            subtitles: props.subtitlesParent || [],
            newSubtitle: null,
        };
    }

    componentDidMount() {
        if (this.props.provider === 'pubnub') {
            pubnub.addListener({
                message: this.handleMessagePubnub,
            });

            pubnub.subscribe({
                channels: [this.props.channel],
            });
        }
    }

    handleMessagePubnub(msg) {
        // Render messages from other users
        // Without this validation, the message will be shown twice
        if (pubnub.getUUID() !== msg.publisher) {
            const data = JSON.parse(msg.message);
            if (data.module === 'subtitles') {
                this.setState({
                    ...this.state,
                    newSubtitle: data,
                });
            }
        }
    }

    renderSubtitle(sub) {
        if (sub) {
            const subtitlesList = [...this.state.subtitles, sub];
            this.setState({ ...this.state, subtitles: subtitlesList });
        } else {
            this.setState({ ...this.state, subtitles: [] });
        }
    }

    render() {
        return (
            <SubtitlesUI
                subtitles={this.state.subtitles}
                renderSubtitle={this.renderSubtitle}
                buildMessageSubtitle={buildMessageSubtitle}
                newSubtitle={this.state.newSubtitle}
                pubnub={pubnub}
                channelIdPubnub={this.props.channel}
                preRequestSubtitles={handlePreRequestSubtitles}
                {...this.props}
            />
        );
    }
}

SubtitlesWrapper.defaultProps = {
    defaultBilingual: false,
    initialVisible: true,
    fontSizeSubtitles: 2,
    languagesPreTranslate: null,
    render: null,
    showInput: true,
    showButtonCloseComponent: true,
    showSourceLanguageSelect: true,
    showTargetLanguageSelect: true,
    showFlipButton: false,
    showSpeedOption: false,
    speechRecognition: false,
    postRequestSubtitles: null,
    ttsOption: false,
};

SubtitlesWrapper.propTypes = {
    defaultBilingual: PropTypes.bool,
    initialSourceLanguage: PropTypes.string.isRequired,
    initialTargetLanguage: PropTypes.string.isRequired,
    initialVisible: PropTypes.bool.isRequired,
    preRequestSubtitles: PropTypes.func,
    postRequestSubtitles: PropTypes.func,
    render: PropTypes.func,
    speechRecognition: PropTypes.bool,
    showFlipButton: PropTypes.bool,
    showSpeedOption: PropTypes.bool,
    style: PropTypes.object,
    ttsOption: PropTypes.bool,
    username: PropTypes.string.isRequired,
};

const Subtitles = {
    SubtitlesUI: SubtitlesWrapper,
    buildMessageSubtitle,
};
export default Subtitles;
