import React, { useState, useEffect, useRef } from 'react';
import { fetchTranslate } from '../request/translate';
import { useStateValue, useTokenContext } from '../store';
import Select from '../components/select';
import {
    Times,
    Cog,
    UserCircle,
    Microphone,
    MicrophoneSlash,
    Attach,
    FileUpload,
    FileAttach,
} from '../assets/svg/icons';
import parserHyperlink from '../utils/parserHyperlink';

const getShortName = name => {
    if (name.split(' ').length > 1) {
        return `${name.split(' ')[0]} ${name.split(' ')[1].slice(0, 1)}.`;
    } else {
        return name;
    }
};

function ChatUI(props) {
    const {
        attachFile,
        addMessageToChat,
        buildMessageChat,
        channelIdPubnub,
        endpoint,
        fontSizeChat,
        initialSourceLanguage,
        initialTargetLanguage,
        initialVisible,
        messagesList,
        messageFromThirdParty,
        provider,
        pubnub,
        showButtonCloseComponent,
        speechRecognition,
        username,
    } = props;

    const [
        {
            chat: { targetLanguage, sourceLanguage, visible, messageFromOtherComponent },
            stt,
        },
        dispatch,
    ] = useStateValue();
    const { token, languages } = useTokenContext();
    const [inputChat, setInputChat] = useState(null);
    const [fontSize, setFontSize] = useState(fontSizeChat);
    const [visibleOptions, setVisibleOptions] = useState(false);
    const [fileChat, setFileChat] = useState(null);

    // When component is mounted, set default values to context
    useEffect(() => {
        const defaultSourceLanguage = languages.find(lng => lng.value === initialSourceLanguage);
        const defaultTargetLanguage = languages.find(lng => lng.value === initialTargetLanguage);
        dispatch({
            type: 'CHAT_setup',
            value: {
                sourceLanguage: defaultSourceLanguage,
                targetLanguage: defaultTargetLanguage,
                visible: initialVisible,
            },
        });
    }, []);

    useEffect(() => {
        if (messageFromThirdParty) {
            if (messageFromThirdParty.file) {
                displayChatMessage(
                    targetLanguage.value,
                    targetLanguage.value,
                    messageFromThirdParty.file.name,
                    messageFromThirdParty.name,
                    'other',
                    'file',
                    messageFromThirdParty.file.url,
                );
            } else {
                const { name, speakerLanguage, text } = messageFromThirdParty;
                const shortName = getShortName(name);
                displayChatMessage(
                    speakerLanguage,
                    targetLanguage.value,
                    text,
                    shortName,
                    'other',
                    'text',
                );
            }
        }
    }, [messageFromThirdParty]);

    useEffect(() => {
        if (messageFromOtherComponent) {
            const { name, speakerLanguage, text } = messageFromOtherComponent;
            displayChatMessage(speakerLanguage, targetLanguage.value, text, name, 'own', 'text');
            const msg = buildMessageChat(name, speakerLanguage, text);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
        }
    }, [messageFromOtherComponent]);

    // Update scroll on Chat messages when there's a new text
    useEffect(() => {
        handleScroll();
    }, [messagesList]);

    // font size of subtitles content
    useEffect(() => {
        if (fontSizeChat && fontSizeChat > 0 && fontSizeChat < 6) {
            setFontSize(fontSizeChat);
        }
    }, [fontSizeChat]);

    /**
     * Show the text in the body of component like text list
     * @param {string} sourceLanguage   Source language "en"
     * @param {string} targetLanguage   Target language "es"
     * @param {string} text             Chat text
     * @param {string} name             User name
     * @param {string} source           Message source: "other" or "own"
     * @param {string} type             Message type: "text" or "file"
     * @param {string} urlFile          Link URL file
     */
    const displayChatMessage = async (
        sourceLanguage,
        targetLanguage,
        text,
        name,
        source,
        type,
        urlFile,
    ) => {
        const msg = buildMessageChat(name, sourceLanguage, text);
        if (type === 'text') {
            if (text && text.trim() !== '') {
                if (sourceLanguage !== targetLanguage) {
                    const chatTranslated = await fetchTranslate(
                        endpoint,
                        text,
                        sourceLanguage,
                        targetLanguage,
                        undefined,
                    );
                    if (
                        chatTranslated &&
                        chatTranslated[endpoint.valueResponse] &&
                        chatTranslated[endpoint.valueResponse] !== ''
                    ) {
                        const msgTranslated = buildMessageChat(
                            name,
                            targetLanguage,
                            chatTranslated[endpoint.valueResponse],
                            source,
                        );
                        addMessageToChat(msgTranslated, source, type);
                    }
                } else {
                    addMessageToChat(msg, source, type);
                }
            }
        } else if (type === 'file') {
            addMessageToChat(msg, source, type, urlFile);
        }
    };

    /**
     * Handle input chat to translate/show text
     * @param {Object} e Class Event of component
     * @return {void}
     */
    const handleInputChat = async e => {
        if (e.key === 'Enter' && !e.shiftKey && inputChat && inputChat.trim() !== '') {
            e.preventDefault();
            const msg = buildMessageChat(username, sourceLanguage.value, inputChat);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
            displayChatMessage(
                sourceLanguage.value,
                targetLanguage.value,
                inputChat,
                username,
                'own',
                'text',
            );
            setInputChat(null);
        }
        if (e.key === 'Enter' && !e.shiftKey && (!inputChat || inputChat.trim() == '')) {
            e.preventDefault();
            return false;
        }
    };

    const triggerInputFile = () => {
        const inputFile = document.getElementsByClassName('input-file-chat')[0];
        inputFile.click();
    };

    const handleInputFileChange = async e => {
        const file = e.target.files[0];
        setFileChat(file);
    };

    const uploadFileChat = async () => {
        try {
            displayChatMessage(
                sourceLanguage.value,
                targetLanguage.value,
                fileChat.name,
                username,
                'own',
                'file',
            );
            setFileChat(null);
            const result = await pubnub.sendFile({
                channel: channelIdPubnub,
                file: fileChat,
            });
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Update class of each <p> element to change font size
     * @param {Integer} size Value to set new font size
     * @return {void}
     */
    const handleFontSize = size => {
        setFontSize(size);
    };

    /**
     * Set scroll to the bottom when new text is rendered
     */
    const handleScroll = () => {
        var container = document.getElementById('list-messages');
        container.scrollTop = container.scrollHeight;
    };

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'CHAT_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleSourceLanguage = language => {
        dispatch({
            type: 'CHAT_changeSourceLanguage',
            value: language,
        });
    };

    /**
     * Handle target language
     * @param {String} language en | es | fr
     */
    const handleTargetLanguage = language => {
        dispatch({
            type: 'CHAT_changeTargetLanguage',
            value: language,
        });
    };

    const handleSTT = () => {
        dispatch({
            type: 'STT_changeTarget',
            value: 'chat',
        });
        dispatch({
            type: 'STT_changeActive',
            value: !stt.active,
        });
    };

    const renderMessage = (key, source, name, text, time, type, urlFile) => {
        const textToRender = parserHyperlink(text);
        if (source === 'own') {
            if (type === 'text') {
                return (
                    <div key={`chat-${key}`} className="msg-user">
                        <div className="tri-right message-user right-top">
                            <p className={`font-size-${fontSize}`}>{textToRender}</p>
                        </div>
                        <div className="date-chat">{time}</div>
                    </div>
                );
            } else if (type === 'file') {
                return (
                    <div key={`chat-${key}`} className="msg-user">
                        <div className="tri-right message-user right-top">
                            <a className={`font-size-${fontSize}`}>
                                <FileAttach />
                                &nbsp;{textToRender}
                            </a>
                        </div>
                        <div className="date-chat">{time}</div>
                    </div>
                );
            }
        } else {
            if (type === 'text') {
                return (
                    <div key={`chat-${key}`} className="msg-voxeet">
                        <div className="avatar-text">
                            <div className="img-voxeet">
                                <UserCircle />
                                <span>{name}</span>
                            </div>
                            <div className="tri-right message-chat left-top">
                                <p className={`font-size-${fontSize}`}>{textToRender}</p>
                            </div>
                        </div>
                        <div className="date-chat">{time}</div>
                    </div>
                );
            } else if (type === 'file') {
                return (
                    <div key={`chat-${key}`} className="msg-voxeet">
                        <div className="avatar-text">
                            <div className="img-voxeet">
                                <UserCircle />
                                <span>{name}</span>
                            </div>
                            <div className="tri-right message-chat left-top">
                                <a
                                    className={`font-size-${fontSize}`}
                                    href={`${urlFile}`}
                                    target="_blank"
                                >
                                    <FileAttach />
                                    &nbsp;{text}
                                </a>
                            </div>
                        </div>
                        <div className="date-chat">{time}</div>
                    </div>
                );
            }
        }
    };

    return (
        <div
            className={`wrapper-container ${!visible ? 'container-disabled' : ''}`}
            style={props.style}
        >
            <div id="chat" className="container-option">
                <div className="header-container">
                    <div className="header-title">
                        <span>Chat</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>

                        {showButtonCloseComponent ? (
                            <button className="a-element" onClick={closeComponent}>
                                <Times />
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="box-subtitle">
                    <div
                        id="list-messages"
                        aria-label="container-messages-chat"
                        className={`body-container ${!visibleOptions ? 'options-disabled' : ''}`}
                    >
                        {messagesList.map((msg, index) =>
                            renderMessage(
                                index,
                                msg.source,
                                msg.name,
                                msg.text,
                                msg.time,
                                msg.type,
                                msg.urlFile,
                            ),
                        )}
                    </div>
                    <div
                        className={`wrapper-input-chat ${
                            speechRecognition ? 'wrapper-input-chat-group' : ''
                        }`}
                    >
                        {speechRecognition ? (
                            <div className="wrapper-button-speech-chat">
                                <button
                                    type="button"
                                    disabled={stt.active && stt.target !== 'chat'}
                                    className={`btn ${
                                        stt.active && stt.target === 'chat' ? 'btn-green' : ''
                                    }`}
                                    onClick={handleSTT}
                                >
                                    {stt.active && stt.target ? (
                                        <Microphone />
                                    ) : (
                                        <MicrophoneSlash />
                                    )}
                                </button>
                            </div>
                        ) : null}
                        {attachFile ? (
                            <div className="wrapper-button-attach-chat">
                                <input
                                    type="file"
                                    className="input-file-chat"
                                    style={{ position: 'absolute', left: -1000 }}
                                    onChange={handleInputFileChange}
                                />
                                <button type="button" className="btn" onClick={triggerInputFile}>
                                    <Attach />
                                </button>
                            </div>
                        ) : null}
                        <div className={`wrapper-textarea-chat ${fileChat ? 'div-file' : ''}`}>
                            {!fileChat ? (
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Type text here"
                                    rows="4"
                                    value={inputChat || ''}
                                    onKeyPress={handleInputChat}
                                    onChange={e => setInputChat(e.target.value)}
                                ></textarea>
                            ) : (
                                <div className="wrapper-input-chat-selected">
                                    <p>{fileChat.name}</p>
                                    <span
                                        className="cancel-upload"
                                        onClick={() => setFileChat(null)}
                                    >
                                        <Times />
                                    </span>
                                    <span className="do-upload" onClick={uploadFileChat}>
                                        <FileUpload />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper ">
                                    <span className="settings-container-label">Chat font size</span>
                                    <div className="size-subtitles">
                                        <label style={{ fontSize: 10 }}>A</label>
                                        <input
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="1"
                                            value={fontSize}
                                            onChange={e => handleFontSize(e.target.value)}
                                        ></input>
                                        <label style={{ fontSize: 14 }}>A</label>
                                    </div>
                                </div>
                            </div>

                            <div className="settings-container">
                                <div className="settings-container-wrapper target-language">
                                    <span className="settings-container-label">
                                        Target Language
                                    </span>
                                    <div>
                                        <Select
                                            handleChange={handleTargetLanguage}
                                            data={languages}
                                            defaultValue={languages.find(
                                                item => item.value === initialTargetLanguage,
                                            )}
                                            value={targetLanguage}
                                        />
                                    </div>
                                </div>
                                <div className="settings-container-wrapper source-language">
                                    <span className="settings-container-label">
                                        Source Language
                                    </span>
                                    <div>
                                        <Select
                                            handleChange={handleSourceLanguage}
                                            data={languages}
                                            defaultValue={languages.find(
                                                item => item.value === initialSourceLanguage,
                                            )}
                                            value={sourceLanguage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatUI;
