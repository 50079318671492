export const initialStateSpeechToText = {
    visible: false,
    active: false,
    language: null,
    target: null, // chat or subtitle component
};

export function speechToTextReducer(state, action) {
    switch (action.type) {
        case 'STT_setup':
            return {
                ...state,
                ...action.value,
            };

        case 'STT_changeLanguage':
            return {
                ...state,
                language: action.value,
            };

        case 'STT_changeActive':
            return {
                ...state,
                active: action.value,
            };

        case 'STT_changeVisible':
            return {
                ...state,
                visible: action.value,
            };

        case 'STT_changeTarget':
            return {
                ...state,
                target: action.value,
            };

        default:
            return state;
    }
}
