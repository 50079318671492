import React from 'react';
import PropTypes from 'prop-types';
import ChatUI from './component';
import pubnub from '../pubnub';

const buildMessageChat = (name, speakerLanguage, text, type) => {
    return {
        module: 'chat',
        time: new Date().toTimeString().split(' ')[0],
        name,
        speakerLanguage,
        text,
        type,
    };
};

class ChatWrapper extends React.Component {
    state = {
        messagesList: [],
        messageFromThirdParty: null,
    };

    constructor(props) {
        super(props);
        this.addMessageToChat = this.addMessageToChat.bind(this);
        this.handleMessagePubnub = this.handleMessagePubnub.bind(this);
        this.handleFilePubnub = this.handleFilePubnub.bind(this);
    }

    componentDidMount() {
        if (this.props.provider === 'pubnub') {
            pubnub.addListener({
                message: this.handleMessagePubnub,
                file: this.handleFilePubnub,
            });

            pubnub.subscribe({
                channels: [this.props.channel],
            });
        }
    }

    handleMessagePubnub(msg) {
        // Show messages from another users
        // Without this validation, the message will be shown twice
        if (pubnub.getUUID() !== msg.publisher && msg.channel === this.props.channel) {
            const data = JSON.parse(msg.message);
            if (data.module === 'chat') {
                this.setState({ ...this.state, messageFromThirdParty: data });
            }
        }
    }

    async handleFilePubnub(msg) {
        if (pubnub.getUUID() !== msg.publisher) {
            const dataUser = await pubnub.objects.getUUIDMetadata({
                uuid: msg.publisher,
            });
            const data = {
                name: dataUser.data.name,
                ...msg,
            };
            this.setState({ ...this.state, messageFromThirdParty: data });
        }
    }

    addMessageToChat(msg, source, type, urlFile) {
        const chatList = [...this.state.messagesList, { ...msg, source, type, urlFile }];
        this.setState({ messagesList: chatList });
    }

    render() {
        return (
            <ChatUI
                messagesList={this.state.messagesList}
                messageFromThirdParty={this.state.messageFromThirdParty}
                addMessageToChat={this.addMessageToChat}
                buildMessageChat={buildMessageChat}
                pubnub={pubnub}
                channelIdPubnub={this.props.channel}
                {...this.props}
            />
        );
    }
}

ChatWrapper.defaultProps = {
    attachFile: false,
    fontSizeChat: 2,
    initialVisible: true,
    showButtonCloseComponent: true,
    speechRecognition: false,
};

ChatWrapper.propTypes = {
    attachFile: PropTypes.bool,
    initialSourceLanguage: PropTypes.string.isRequired,
    initialTargetLanguage: PropTypes.string.isRequired,
    initialVisible: PropTypes.bool.isRequired,
    provider: PropTypes.string.isRequired,
    speechRecognition: PropTypes.bool,
    style: PropTypes.object,
    username: PropTypes.string.isRequired,
};

const Chat = {
    ChatUI: ChatWrapper,
    buildMessageChat,
};
export default Chat;
