import axios from 'axios';

/**
 * Make request to API Text to Text
 * Endpoint must be and object with method and url sent from root layer (e.g. App.js)
 * endpoint object e.g. { method: "GET", url: "https://endpoint.com/Translation?text=:text&sourceLanguage=:sourceLanguage..."}
 * @param {object} endpoint         to make request to API. Must have "method" and "url" with params
 * @param {string} text             text to translate
 * @param {string} sourceLanguage   e.g. "es" (spanish)
 * @param {string} targetLanguage   e.g. "en" (english)
 * @param {object} auth             auth credentials
 * @return {object}
 */
const fetchTranslate = async (endpoint, text, sourceLanguage, targetLanguage, auth) => {
    const method = endpoint.method;
    const url = buildUrl(endpoint.url, text, sourceLanguage, targetLanguage, undefined);

    try {
        const response = await axios({
            method,
            url,
            headers: {
                // ...auth.headers,
            },
        });

        if (response && response.status === 200) return response.data;
        else throw Error(response);
    } catch (err) {
        throw Error(err);
    }
};

// 1. plainURL must be an object with method and generic url
// The generic url is a string how describe the whole body of the request to build with values to request
// e.g. "https://endpoint.com/Translation?text=:text&sourceLanguage=:sourceLanguage..."}
// the :text param means that "text" it's dinamyc value sent from UI. e.g: "Hello world"
/*
    plainUrl = {
        method: 'GET',
        url: 'https://api.worldwidetechconnections.com/api/Translation?text=:text&sourceLanguage=:sourceLanguage&targetLanguage=:targetLanguage&vendor=:vendor&token=:token',
    }
*/

const buildUrl = (plainUrl, text, sourceLanguage, targetLanguage, token) => {
    const url = plainUrl
        .replace(':text', text)
        .replace(':sourceLanguage', sourceLanguage)
        .replace(':targetLanguage', targetLanguage)
        .replace(':token', token);

    return url;
};

export { fetchTranslate };
