import PubNub from 'pubnub';
import { PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } from '../config/constants';

const clientUUID = PubNub.generateUUID();
const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    uuid: clientUUID,
});

export default pubnub;
