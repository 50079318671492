import axios from '../config/axios';

/**
 * Make request to API Text to Speech
 * Endpoint must be and object with method and url sent from root layer (e.g. App.js)
 * endpoint object e.g. { method: "GET", url: "https://core.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage?text=:text"}
 * @param {object} endpoint         to make request to API. Must have "method" and "url" with params
 * @param {string} text             text to request audio
 * @param {string} sourceLanguage   e.g. "en" (english)
 * @param {string} voice            e.g. "Mark"
 * @return {object}
 */
const fetchTextToSpeech = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
    const method = endpoint.method;
    const url = buildUrl(endpoint.url, text, sourceLanguage, voice);

    try {
        let response = await axios({
            responseType: 'arraybuffer',
            headers: {
                authorization: auth.apiKey,
            },
            method,
            url,
        });
        let blob = new Blob([response.data], { type: 'audio/mp3' });
        const downloadUrl = window.webkitURL.createObjectURL(blob);
        return { downloadUrl, indexCall: itemTtsCall };
    } catch (err) {
        return {
            indexCall: itemTtsCall,
            error: err,
        };
    }
};

// 1. plainURL must be an object with method and generic url
// The generic url is a string how describe the whole body of the request to build with values to request
// e.g. "https://core.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage?text=:text"}
// the :text param means that "text" it's dinamyc value sent from UI. e.g: "Hello world"
/*
    plainUrl = {
        method: 'GET',
        url: 'https://core.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage?text=:text',
    }
*/

const buildUrl = (plainUrl, text, sourceLanguage, voice) => {
    const url = plainUrl
        .replace(':text', text)
        .replace(/:sourceLanguage/g, sourceLanguage)
        .replace(':voice', voice);

    return url;
};

export { fetchTextToSpeech };
