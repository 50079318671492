export const initialStateTextToSpeech = {
    vendor: null,
    language: null,
    visible: false,
    active: false,
    gender: 'male',
    voice: null,
    subsToTTS: null,
    playbackRate: 1,
};

export function textToSpeechReducer(state, action) {
    switch (action.type) {
        case 'TTS_setup':
            return {
                ...state,
                ...action.value,
            };
        case 'TTS_changeVendor':
            return {
                ...state,
                vendor: action.value,
            };

        case 'TTS_changeLanguage':
            return {
                ...state,
                language: action.value,
            };

        case 'TTS_changeActive':
            return {
                ...state,
                active: action.value,
            };

        case 'TTS_changeVisible':
            return {
                ...state,
                visible: action.value,
            };

        case 'TTS_changeGender':
            return {
                ...state,
                gender: action.value,
            };

        case 'TTS_changeVoice':
            return {
                ...state,
                voice: action.value,
            };

        case 'TTS_setNewTTS':
            return {
                ...state,
                subsToTTS: action.value,
            };

        case 'TTS_changePlayBackRate':
            return {
                ...state,
                playbackRate: Number(action.value),
            };

        default:
            return state;
    }
}
