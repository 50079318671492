import React from 'react';
import Select from 'react-select';

const customStyles = {
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '100%',
    }),
    container: (provided, state) => ({
        ...provided,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
    control: (base, state) => ({
        ...base,
        height: '30px',
        minHeight: '30px',
        alignItems: 'flex-start',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 20,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

const CustomOption = props => {
    return !props.isDisabled ? (
        <div
            {...props.innerProps}
            style={{ display: 'flex', padding: 5 }}
            className="option-select"
        >
            {props.data.img ? <img style={{ width: 20, height: 20 }} src={props.data.img} /> : null}
            <label style={{ margin: 0, marginLeft: 5 }}>{props.label}</label>
        </div>
    ) : null;
};

const SingleValueCustom = props => {
    return (
        <div style={{ display: 'flex', paddingLeft: 5 }}>
            {props.data.img ? <img style={{ width: 20, height: 20 }} src={props.data.img} /> : null}
            <label style={{ margin: 0, marginLeft: 5 }}>{props.data.label}</label>
        </div>
    );
};

const SingleSelect = props => {
    const { handleChange, data, defaultValue, placeholder } = props;
    if (!data) return null;
    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            components={{ Option: CustomOption, SingleValue: SingleValueCustom }}
            defaultValue={defaultValue || data[0]}
            isSearchable={true}
            menuPlacement="auto"
            name="color"
            styles={customStyles}
            onChange={handleChange}
            options={data}
            menuPortalTarget={document.querySelector('body')}
            placeholder={placeholder || 'Select...'}
            {...props}
        />
    );
};

const GroupedSelect = props => {
    const { handleChange, data, defaultValue, placeholder } = props;
    if (!data) return null;
    return (
        <Select
            className="basic-single"
            classNamePrefix="select"
            components={{ Option: CustomOption, SingleValue: SingleValueCustom }}
            defaultValue={defaultValue || data[0]}
            isSearchable={true}
            menuPlacement="auto"
            name="color"
            styles={customStyles}
            onChange={handleChange}
            options={data}
            placeholder={placeholder || 'Select...'}
            menuPortalTarget={document.querySelector('body')}
        />
    );
};

export default SingleSelect;
export { GroupedSelect };
