import React from 'react';

const URL_REGEX = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
// replace hyperlink
const addProtocol = str => {
    if (str.match('http')) return str;
    else return `http://${str}`;
};

const getLabelLink = str => {
    if (str.length >= 30) {
        return `${str.substring(0, 29)}...`;
    }
    return str;
};
const parserHyperlink = text => {
    // URL REGEX url with & without protocol
    // i.e: https://google.com, google.com
    // if url does not provide protocol then parserHyperlink() will parser it
    const newText = text.split(' ').map((part, index) =>
        URL_REGEX.test(part) ? (
            <a href={addProtocol(part)} target="_blank" key={`sub-link-${index}`}>
                {getLabelLink(part)}{' '}
            </a>
        ) : (
            part + ' '
        ),
    );

    return newText;
};

export default parserHyperlink;
