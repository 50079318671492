import axios from 'axios';

/**
 * Make request to API Speech to Text
 * Endpoint must be and object with method and url sent from root layer (e.g. App.js)
 * endpoint object e.g. { method: "GET", url: "https://endpoint.com/SpeechToText?sourceLanguage=:sourceLanguage..."}
 * @param {object} endpoint         to make request to API. Must have "method" and "url" with params
 * @param {string} sourceLanguage   e.g. "en-US" (english)
 * @param {object} headers          headers
 * @return {object}
 */
const fetchSpeechToText = async (endpoint, audio, sourceLanguage, headers = undefined) => {
    const method = endpoint.method;
    const url = buildUrl(endpoint.url, sourceLanguage);
    let request = null;

    if (endpoint.isFormData) {
        request = buildFormDataRequest(audio, method, url, headers);
    } else {
        request = buildWavRequest(audio, method, url, headers);
    }

    try {
        const response = await axios(request);
        if (response && response.status === 200) return response.data;
        else {
            return {
                [endpoint.valueResponse]: '',
            };
        }
    } catch (err) {
        return {
            [endpoint.valueResponse]: '',
        };
    }
};

const buildFormDataRequest = (audio, method, url, headers) => {
    const formData = new FormData();
    formData.append('file', audio, 'speechtotext.wav');

    const request = {
        headers: {
            ...headers,
        },
        method,
        url,
        data: formData,
        mimeType: 'multipart/form-data',
    };

    return request;
};

const buildWavRequest = (audio, method, url, headers) => {
    const request = {
        headers: {
            'Content-Type': 'audio/wav',
            ...headers,
        },
        method,
        url,
        data: audio,
        processData: false,
        contentType: false,
    };

    return request;
};

// 1. plainURL must be an object with method and generic url
// The generic url is a string how describe the whole body of the request to build with values to request
// e.g. "https://endpoint.com/Translation?text=:text&sourceLanguage=:sourceLanguage..."}
// the :text param means that "text" it's dinamyc value sent from UI. e.g: "Hello world"
/*
    plainUrl = {
        method: 'POST',
        url: 'https://core.worldwidetechconnections.com/services/stt/:sourceLanguage/:sourceLanguage?text=:text',
    }
*/

const buildUrl = (plainUrl, sourceLanguage) => {
    const url = plainUrl.replace(/:sourceLanguage/g, sourceLanguage);

    return url;
};
export { fetchSpeechToText };
